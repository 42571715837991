import { HttpClient, UnexpectedError } from '@azos/core'
import { CreateDPSUseCase, GetDPSQuestionnaireUseCase } from 'domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type IDPSRepository = GetDPSQuestionnaireUseCase & CreateDPSUseCase

export class DPSRepository implements IDPSRepository {
  constructor(
    private readonly baseUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  async getDPSQuestionnaire(): Promise<GetDPSQuestionnaireUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/dps`,
      method: 'get',
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as GetDPSQuestionnaireUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }

  async createDPS(
    params: CreateDPSUseCase.Params,
  ): Promise<CreateDPSUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/dps`,
      method: 'post',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as CreateDPSUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }
}
