import { HttpClient, UnauthorizedError, UnexpectedError } from '@azos/core'
import {
  UpdateUserUseCase,
  UserManagementStatusUseCase,
} from '@domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type IUserRepository = UpdateUserUseCase & UserManagementStatusUseCase

export class UserRepository implements IUserRepository {
  constructor(
    private readonly baseUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  async updateUser(
    params: UpdateUserUseCase.Params,
  ): Promise<UpdateUserUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/user-management`,
      method: 'post',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as UpdateUserUseCase.Response
      case HttpStatusCode.UNAUTHORIZED:
        throw new UnauthorizedError(response?.message)
      default:
        throw new UnexpectedError()
    }
  }

  async getUserManagementStatus(): Promise<UserManagementStatusUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/user-management/status`,
      method: 'get',
      body: null,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as UserManagementStatusUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }
}
