import React from 'react'

import analytics from '@azos/analytics'
import { ClickPoliciesCardType } from '@azos/analytics/src/domain/models'
import { CheckoutPaymentFrequency } from '@azos/core'
import { BadgeTypeProps, Utils } from '@azos/shared'
import { Badge } from '@azos/shared/src/components/v2'
import { BadgeType, PolicyStatusResponse } from '@domain/models'
import { MENU } from '@main/config/menu'
import { useFetchPolicyDetails } from '@presentation/hooks/useFetchPolictDetails'
import { useRouter } from 'next/router'

import { ModalPolicyReadjustmentValue } from '../../ModalPolicyReadjustmentValue'
import { PolicyCardProductProps } from './PolicyCardProduct.props'
import { Root } from './PolicyCardProduct.styles'

const PolicyCardProduct: React.VFC<PolicyCardProductProps> = ({
  status,
  coverages,
  created,
  premium,
  policyId,
  isPolicyReadjustmentActive,
  readjustment,
  onClick,
  onPayment,
  frequency,
  policyNumber,
}) => {
  const router = useRouter()

  const [isModalPolicyReadjustmentValue, setIsModalPolicyReadjustmentValue] =
    React.useState(false)

  const [statusList, setStatusList] = React.useState<PolicyStatusResponse[]>([])

  const handleClick = () => {
    analytics.dashboard.policy.view.clickPolicyCard.execute({
      type: {
        active: String(!!created),
        proposal: String(!created),
      },
      page: {
        home: String(router.pathname === `/${MENU.INICIO}`),
        policyScreen: String(router.pathname === `/${MENU.MINHAS_APOLICES}`),
      },
    })

    analytics.dashboard.policies.clickPoliciesCards.execute({
      type: ClickPoliciesCardType.POLICIES,
    })
    onClick()
  }

  React.useEffect(() => {
    if (status) {
      const tempStatusList = [status[0]]

      const readjustmentPolicy = status.find(
        status => status.badgeType === BadgeType.READJUSTMENT_POLICY,
      )

      if (readjustmentPolicy && isPolicyReadjustmentActive)
        tempStatusList.push(readjustmentPolicy)

      setStatusList(tempStatusList)
    }
  }, [isPolicyReadjustmentActive, status])

  const handleBadgeClick = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    type?: BadgeType,
  ) => {
    event.stopPropagation()

    if (readjustment?.readjustmentRemainingDays)
      analytics.dashboard.policies.clickReadjustmentBadge.execute({
        day: readjustment?.readjustmentRemainingDays,
      })

    if (
      type &&
      type === BadgeType.READJUSTMENT_POLICY &&
      isPolicyReadjustmentActive
    ) {
      setIsModalPolicyReadjustmentValue(true)
    }
  }

  return (
    <>
      <Root onClick={() => handleClick()} data-testid="policy-card-item">
        <div className="heading">
          <div className="topbar">
            <div className="badges">
              {statusList.map(statusProp => (
                <Badge
                  key={statusProp.badgeType}
                  type={statusProp.type as BadgeTypeProps}
                  onClick={event =>
                    handleBadgeClick(event, statusProp.badgeType)
                  }
                  clickable={
                    statusProp.badgeType === BadgeType.READJUSTMENT_POLICY
                  }
                  icon={statusProp.icon}
                  borderRadius={4}
                >
                  {statusProp.label}
                </Badge>
              ))}
            </div>
          </div>

          <span className="policy-card-product__premium">
            R$ <strong>{Utils.currency.format(premium, '')}</strong> /
            {frequency === CheckoutPaymentFrequency.MONTHLY ? 'mês' : 'ano'}
          </span>
        </div>

        <ul className="products">
          {coverages.map(coverage => (
            <li key={coverage.label}>
              <strong>{coverage.label}</strong>
              <span>{coverage.value}</span>
            </li>
          ))}
        </ul>
      </Root>
      <ModalPolicyReadjustmentValue
        open={isModalPolicyReadjustmentValue}
        policyId={policyId}
        policyNumber={policyNumber}
        readjustment={null}
        onClose={() => setIsModalPolicyReadjustmentValue(false)}
      />
    </>
  )
}

export default PolicyCardProduct
