import React from 'react'

import { useDashboardToken } from '@azos/account'
import { useUpsellRedirect } from '@presentation/hooks/useUpsellRedirect'
import { useWebview } from '@presentation/hooks/useWebview'
import { useRouter } from 'next/router'

import { Root } from './MainContainer.styles'

const MainContainer: React.FCC = ({ children }) => {
  const router = useRouter()

  useDashboardToken({ qs: router.query })
  useWebview({ qs: router.query })
  useUpsellRedirect({ qs: router.query })

  return <Root>{children}</Root>
}

export default MainContainer
