import React from 'react'

import { useNotification } from '@Azos-Seguros/apollo'
import { AppProvider, AuthSuccessfulData } from '@azos/account'
import analytics from '@azos/analytics'
import { env } from '@main/config/env'
import * as Sentry from '@sentry/nextjs'
import Router from 'next/router'

const PUBLIC_ROUTES = ['/login', '/cadastro']

const AccountProvider: React.FCC = ({ children }) => {
  const { addNotification } = useNotification()

  const handleAuthSuccessful = async (data: AuthSuccessfulData) => {
    await analytics.initializeLoggedUser(env.MIX_PANEL_ID, {
      email: data.user.email,
      displayName: data.user.displayName,
      isInsured: data.user.isInsured,
      isInsuredByBroker: data.user.isInsuredByBroker,
      isBroker: data.user.isBroker,
      brokerUser: data.user.brokerUser,
    })

    Sentry.configureScope(scope => {
      scope.setExtras({
        isInsured: data.user.isInsured,
        isInsuredByBroker: data.user.isInsuredByBroker,
        isBroker: data.user.isBroker,
      })
      scope.setUser({
        email: data.user.email,
        username: data.user.displayName,
      })
    })

    if (!PUBLIC_ROUTES.includes(Router.pathname)) {
      return
    }

    await Router.push(data.from || '/inicio')
  }

  const handleAuthError = React.useCallback(
    (
      description = 'Falha ao se autenticar, verifique seus dados e tente novamente',
    ) => {
      addNotification({
        description,
        variant: 'error',
      })
    },
    [addNotification],
  )

  const handleAuthInfo = React.useCallback(
    (description = 'OK!') => {
      addNotification({
        description,
        variant: 'primary',
      })
    },
    [addNotification],
  )

  const handleSignOut = React.useCallback(() => {
    const from = '/seguro/beneficiarios'
    Router.push(`/login?origin=${from}`)
  }, [])

  return (
    <AppProvider
      onSuccessful={handleAuthSuccessful}
      onError={handleAuthError}
      onInfo={handleAuthInfo}
      onSignOut={handleSignOut}
    >
      {children}
    </AppProvider>
  )
}

export default AccountProvider
