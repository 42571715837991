import React from 'react'

import { useAuth } from '@azos/account'
import {
  DashboardSliderCardsResponse,
  DashboardSliderCardsType,
} from '@data/models'
import { makeListSliderCardsByIdService } from '@main/factories/services'

export type BannersContextData = {
  banners: DashboardSliderCardsResponse[]
  setBanners: (policies: DashboardSliderCardsResponse[]) => void
  onboardingBanner: DashboardSliderCardsResponse | null
  changePaymentBanner: DashboardSliderCardsResponse | null
}

export const BannersContext = React.createContext({} as BannersContextData)

type BannersProviderProps = {
  banners?: DashboardSliderCardsResponse[]
}

const getDashboardCardItem = makeListSliderCardsByIdService()

const BannersProvider: React.FCC<BannersProviderProps> = ({
  banners = [],
  children,
}) => {
  const { user } = useAuth()

  const [bannersData, setBannersData] =
    React.useState<DashboardSliderCardsResponse[]>(banners)

  const [onboardingBanner, setOnboardingBanner] =
    React.useState<DashboardSliderCardsResponse | null>(null)

  const [changePaymentBanner, setChangePaymentBanner] =
    React.useState<DashboardSliderCardsResponse | null>(null)

  React.useEffect(() => {
    if (user) {
      getDashboardCardItem
        .execute({
          cardId: DashboardSliderCardsType.ONBOARDING,
        })
        .then(response => setOnboardingBanner(response))
        .catch(() => null)
    }
  }, [user])

  React.useEffect(() => {
    if (user) {
      getDashboardCardItem
        .execute({
          cardId: DashboardSliderCardsType.CHANGE_PAYMENT_TYPE,
        })
        .then(response => setChangePaymentBanner(response))
        .catch(() => null)
    }
  }, [user])

  return (
    <BannersContext.Provider
      value={{
        banners: bannersData,
        setBanners: setBannersData,
        onboardingBanner,
        changePaymentBanner,
      }}
    >
      {children}
    </BannersContext.Provider>
  )
}

export default BannersProvider
