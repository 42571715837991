import { AppError, HttpClient } from '@azos/core'
import {
  ListCitiesUseCase,
  ListStatesUseCase,
  LoadByZipCodeUseCase,
} from '@domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type IAddressesRepository = ListCitiesUseCase &
  ListStatesUseCase &
  LoadByZipCodeUseCase

export class AddressesRepository implements IAddressesRepository {
  constructor(
    private readonly baseUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  async listCities(
    state: ListCitiesUseCase.Params,
  ): Promise<ListCitiesUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/addresses/states/${state}/cities`,
      method: 'get',
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as ListCitiesUseCase.Response
      default:
        throw new AppError(response?.message)
    }
  }

  async listStates(): Promise<ListStatesUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/addresses/states`,
      method: 'get',
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as ListStatesUseCase.Response
      default:
        throw new AppError(response?.message)
    }
  }

  async loadByZipCode(
    zipCode: LoadByZipCodeUseCase.Params,
  ): Promise<LoadByZipCodeUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/addresses/zip-codes/${zipCode}`,
      method: 'get',
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as LoadByZipCodeUseCase.Response
      default:
        throw new AppError(response?.message)
    }
  }
}
