import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'

import { PaymentHistoryItems } from '../PaymentHistoryItems'
import { PaymentHistoryModal } from '../PaymentHistoryModal'
import { PaymentHistoryProps } from './PaymentHistory.props'
import { Root } from './PaymentHistory.styles'

const PaymentHistory: React.VFC<PaymentHistoryProps> = ({ invoices }) => {
  const [modalHistory, setModalHistory] = React.useState(false)

  return (
    <Root>
      <div className="payment-history__header">
        <Typography variant="body2" className="payment-history__header-title">
          Histórico de <strong>pagamento</strong>
        </Typography>

        {invoices.length > 3 && (
          <a
            href="#"
            className="payment-history__header-see-more"
            onClick={() => setModalHistory(true)}
          >
            <Typography className="payment-history__header-see-more--title">
              Ver tudo
            </Typography>
            <i className="icon-arrow-topright payment-history__header-icon" />
          </a>
        )}
      </div>

      {invoices.length > 0 ? (
        <div className="payment-history__wrapper">
          <PaymentHistoryItems invoices={invoices}></PaymentHistoryItems>

          <PaymentHistoryModal
            invoices={invoices}
            open={modalHistory}
            onClose={() => setModalHistory(false)}
          />
        </div>
      ) : null}
    </Root>
  )
}

export default PaymentHistory
