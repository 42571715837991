import React from 'react'

import { PaymentMethod } from '@azos/core'
import { Spinner } from '@azos/shared'
import { Button, RadioButton } from '@azos/shared/src/components/v2'
import { BillingServiceSource, ContractType } from '@domain/models'
import Image from 'next/image'

import creditCards from '../../../../assets/icons/creditCards.svg'
import { CardPolicyCreditCard } from './components'
import { PolicyEditPaymentProps } from './PolicyEditPayment.props'
import { Root } from './PolicyEditPayment.styles'

const PolicyEditPayment: React.VFC<PolicyEditPaymentProps> = ({
  id,
  paymentMethod,
  onPaymentChangeError,
  onPaymentChangeSuccess,
  changePaymentMethodService,
  changePaymentMethodV2Service,
  billingServiceSource,
}) => {
  const [loading, setLoading] = React.useState(false)
  const [value, setValue] = React.useState<PaymentMethod>(PaymentMethod.PIX)
  const [modalCreditCard, setModalCreditCard] = React.useState(false)

  const handleEdit = React.useCallback((value: PaymentMethod) => {
    if (value === PaymentMethod.CREDIT_CARD) {
      setModalCreditCard(true)
    } else {
      setValue(value)
    }
  }, [])

  const handleChangePayment = React.useCallback(async () => {
    setLoading(true)

    try {
      if (billingServiceSource === BillingServiceSource.IUGU) {
        await changePaymentMethodService.execute({
          id,
          type: ContractType.policy,
          paymentMethod: value,
        })
      } else {
        await changePaymentMethodV2Service.execute({
          id,
          paymentMethod: value,
        })
      }

      onPaymentChangeSuccess()
    } catch (error) {
      onPaymentChangeError()
    }

    setLoading(false)
  }, [
    billingServiceSource,
    changePaymentMethodService,
    changePaymentMethodV2Service,
    id,
    onPaymentChangeError,
    onPaymentChangeSuccess,
    value,
  ])

  React.useEffect(() => {
    return () => {
      changePaymentMethodService.dispose?.()
      changePaymentMethodV2Service.dispose?.()
    }
  }, [changePaymentMethodService, changePaymentMethodV2Service])

  return (
    <>
      <Root>
        <h3>Selecione um meio de pagamento</h3>

        <div>
          <RadioButton
            showInput
            className="policy-edit-payment__radio-button"
            name="paymentEdit"
            columns={1}
            value={value}
            options={[
              {
                value: PaymentMethod.CREDIT_CARD,
                label: (
                  <span className="label-option">
                    <span className="icon-credit-card" />
                    <span className="credit-card">
                      Cartão de crédito
                      <Image src={creditCards} width="140px" height="22px" />
                    </span>
                  </span>
                ),
              },
              {
                value: PaymentMethod.PIX,
                label: (
                  <span className="label-option">
                    <span className="icon-PIX" />
                    Pix
                  </span>
                ),
              },
            ]}
            disabled={loading}
            error={
              value === PaymentMethod.PIX &&
              paymentMethod?.paymentMethod === PaymentMethod.PIX
            }
            helperText={
              value === PaymentMethod.PIX &&
              paymentMethod?.paymentMethod === PaymentMethod.PIX
                ? 'Seu método de pagamento já é Pix'
                : ''
            }
            onChange={value => handleEdit(value as PaymentMethod)}
          />
        </div>

        <Button
          fullWidth
          onClick={handleChangePayment}
          disabled={
            (value === PaymentMethod.PIX &&
              paymentMethod?.paymentMethod === PaymentMethod.PIX) ||
            loading ||
            !value ||
            value === PaymentMethod.CREDIT_CARD
          }
        >
          {loading ? <Spinner size={26} borderSize={3} /> : 'Alterar pagamento'}
        </Button>
      </Root>

      <CardPolicyCreditCard
        open={modalCreditCard}
        billingServiceSource={billingServiceSource}
        onClose={() => setModalCreditCard(false)}
        id={id}
      />
    </>
  )
}

export default PolicyEditPayment
