import React from 'react'

import { Button } from '@Azos-Seguros/apollo'
import { Icon } from '@azos/shared'
import { MENU } from '@main/config/menu'

import { SidebarDashboardItem } from './components'
import { SidebarDashboardProps } from './SidebarDashboard.props'
import { Root } from './SidebarDashboard.styles'

const SidebarDashboard: React.VFC<SidebarDashboardProps> = ({
  value,
  items,
  onChange,
  isUserInsured,
}) => {
  const handleSimulate = () => onChange(MENU.SIMULAR_SEGURO)

  const handleRequestBroker = () => {
    onChange(MENU.SOLICITAR_CORRETOR)
    // [WIP] to be added later on subtaks
  }

  return (
    <Root>
      <div className="background"></div>
      <nav>
        <div>
          <div className="icon-azos-big">
            <Icon.AzosPrimary />
          </div>
          <div className="icon-azos-mobile">
            <Icon.AzosLogo />
          </div>
          {!isUserInsured && (
            <div className="sidebar-dashboard__button-group">
              <Button
                fullWidth
                onClick={handleSimulate}
                className="sidebar-dashboard__button"
              >
                Simular agora
                <i className="icon-add" />
              </Button>
              <Button
                variant="outline"
                fullWidth
                onClick={handleRequestBroker}
                className="sidebar-dashboard__button"
              >
                Solicitar corretor
                <i className="icon-briefcase" />
              </Button>
            </div>
          )}
          <ul>
            {items.map(item => (
              <SidebarDashboardItem
                key={item.value}
                itemValue={value}
                item={item}
                onChange={onChange}
              />
            ))}
          </ul>
        </div>
      </nav>
    </Root>
  )
}

export default SidebarDashboard
