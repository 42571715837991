import {
  GetVindiCreditCardParams,
  GetVindiCreditCardResponse,
} from '@domain/models'

import bffApi from '../bffApi'

export const PoliciesAPI = {
  getVindiCreditCard: async (
    params: GetVindiCreditCardParams,
  ): Promise<GetVindiCreditCardResponse> =>
    await bffApi
      .request({
        url: `/policies/vindi/credit-card/${params.proposalId}`,
        method: 'get',
      })
      .then(response => response.data),
}
