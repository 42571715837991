import { makeAxiosHttpClient } from '@azos/core'
import {
  FinalQuotationsRepository,
  ProposalsRepository,
} from '@data/repositories'
import { env } from '@main/config/env'

export const makeProposalsRepository = () =>
  new ProposalsRepository(env.BFF_BASE_URL, makeAxiosHttpClient())

export const makeFinalQuotationsRepository = (
  httpClient = makeAxiosHttpClient(),
) => new FinalQuotationsRepository(env.BFF_BASE_URL, httpClient)
