import { HttpClient, UnauthorizedError, UnexpectedError } from '@azos/core'
import {
  ListBenefitsUseCase,
  ListNewsBannersUseCase,
  ListSliderCardsByIdUseCase,
  ListSliderCardsUseCase,
  CoverageModalUseCase,
  GetCoverageDetailsUseCase,
  ListProductCardsUseCase,
} from '@domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type ICmsRepository = ListBenefitsUseCase &
  ListNewsBannersUseCase &
  ListSliderCardsUseCase &
  ListSliderCardsByIdUseCase &
  CoverageModalUseCase &
  GetCoverageDetailsUseCase &
  ListProductCardsUseCase

export class CmsRepository implements ICmsRepository {
  constructor(
    private readonly baseUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  async listBenefits(): Promise<ListBenefitsUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/benefits`,
      method: 'get',
      body: null,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as ListBenefitsUseCase.Response
      case HttpStatusCode.FORBIDDEN:
      case HttpStatusCode.UNAUTHORIZED:
        throw new UnauthorizedError()
      default:
        throw new UnexpectedError()
    }
  }

  async listNewsBanners(): Promise<ListNewsBannersUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/news-banners`,
      method: 'get',
      body: null,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as ListNewsBannersUseCase.Response
      case HttpStatusCode.UNAUTHORIZED:
        throw new UnauthorizedError(response?.message)
      default:
        throw new UnexpectedError()
    }
  }

  async listSliderCards(): Promise<ListSliderCardsUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/dashboard-slider-cards`,
      method: 'get',
      body: null,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as ListSliderCardsUseCase.Response
      case HttpStatusCode.UNAUTHORIZED:
        throw new UnauthorizedError(response?.message)
      default:
        throw new UnexpectedError()
    }
  }

  async listSliderCardsById({
    cardId,
  }: ListSliderCardsByIdUseCase.Params): Promise<ListSliderCardsByIdUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/dashboard-slider-cards/${cardId}`,
      method: 'get',
      body: null,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as ListSliderCardsByIdUseCase.Response
      case HttpStatusCode.UNAUTHORIZED:
        throw new UnauthorizedError(response?.message)
      default:
        throw new UnexpectedError()
    }
  }

  async CoverageModal({
    coverageCode,
  }: CoverageModalUseCase.Params): Promise<CoverageModalUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/coverages-modal/${coverageCode}`,
      method: 'get',
      body: null,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as CoverageModalUseCase.Response
      case HttpStatusCode.UNAUTHORIZED:
        throw new UnauthorizedError(response?.message)
      default:
        throw new UnexpectedError()
    }
  }

  async getCoverageDetails({
    coverageCode,
  }: GetCoverageDetailsUseCase.Params): Promise<GetCoverageDetailsUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/coverage-details?productCode=${coverageCode}`,
      method: 'get',
      body: null,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as GetCoverageDetailsUseCase.Response
      case HttpStatusCode.UNAUTHORIZED:
        throw new UnauthorizedError(response?.message)
      default:
        throw new UnexpectedError()
    }
  }

  async listProductCards(): Promise<ListProductCardsUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/products-cards`,
      method: 'get',
      body: null,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as ListProductCardsUseCase.Response
      case HttpStatusCode.UNAUTHORIZED:
        throw new UnauthorizedError(response?.message)
      default:
        throw new UnexpectedError()
    }
  }
}
