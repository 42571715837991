import { authCookies, makeStorageProvider } from '@azos/core'
import axios, { AxiosError } from 'axios'

const api = axios.create({
  baseURL: process.env.BFF_BASE_URL,
})

api.interceptors.request.use((config: any) => {
  if (typeof window !== 'undefined') {
    const storageProvider = makeStorageProvider()

    const firebaseToken = storageProvider.get(authCookies.COOKIE_TOKEN_KEY)

    if (firebaseToken) {
      config.headers.Authorization = `Bearer ${firebaseToken}`
    }
  }

  return config
})

api.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    if (
      (error?.message || '').includes('code 401') ||
      (error?.message || '').includes('Unauthorized')
    ) {
      const storageProvider = makeStorageProvider()

      storageProvider.remove(authCookies.COOKIE_TOKEN_KEY)
      storageProvider.remove(authCookies.COOKIE_USER_KEY)

      window.open('/login?origin=/inicio', '_self')
    }

    return Promise.reject(error)
  },
)

export default api
